@media print {
  @page {
    size: auto;
    margin: 0;
  }

  body {
    margin: 0;
    width: 100%;
    background-color: $white;
    padding: 30px;
  }

  .invoices {
    #primary {
      .navigation__main,
      .navigation__breadcrumb,
      .participant-info {
        display: none;
      }

      .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
        .row {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .card {
      padding-right: 30px;
    }

    .card-body {
      padding-right: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }

    .invoice-header {
      padding-bottom: 0 !important;
    }

    .payment-instructions {
      padding-top: 0 !important;
    }

    footer {
      .footer-links {
        display: none;
      }

      .footer-copyright {
        display: none;
      }

      height: auto;
      padding: 0 !important;

      .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
