.homepage-header {
  background: url(../images/homepage-img.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &--content {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 67px;
    padding-right: 77px;
    h1 {
      font-style: normal;
      font-weight: bold !important;
      font-size: 36px;
      line-height: 45px;
    }

    p {
      font-weight: 600;
    }
  }

  &:has(.maintenance-banner) {
    .homepage-header--content {
      padding-top: 47px;
    }
  }

  .maintenance-banner {
    &--container {
      background-color: rgba(255, 255, 255, 1);
      padding: 10px 10px;
    }
    &--content {
      border: $red 5px solid;
      padding: 20px;
      font-weight: bold;
      color: $red;
    }
  }

  .icon {
    position: absolute;
    bottom: -20px;
    left: 50%;
    padding-top: 5px;
    transform: translateX(-50%);
    height: 38px;
    width: 38px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
  }
}

.how-to-access-auctions-section {
  &.bg-blue {
    background-color: #d3e5ea;
  }

  .col {
    padding: $grid-gutter-width;
    padding-top: 80px;
    padding-bottom: 90px;

    &:first-of-type {
      margin-right: 21px;
    }

    &:last-of-type {
      margin-left: 21px;
    }

    .icon {
      background-color: $white;
      padding: 20px;
      -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.16);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      top: -45px;
      width: 100px;
      height: 100px;

      img {
        height: 60px;
        width: 60px;
      }
    }

    p {
      min-height: 72px;
    }

    .btn {
      width: 100%;
      position: absolute;
      bottom: 2em;
      max-width: calc(100% - 4em);
    }
  }
}

.subscription-form-section {
  label:after {
    content: "*";
    color: $red;
  }

  .form-group {
    text-align: left;
  }
}

.auction-help-section {
  .list-unstyled {
    a {
      text-decoration: underline;
    }

    li {
      a {
        padding: 1rem 0;
        display: inline-block;
      }

      &:first-of-type a {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  p.small {
    font-size: 14px;
  }
}
