.table {
  @extend .mb-0;
  td,
  th {
    @extend .pl-0;
    @extend .pr-0;
  }
  th {
    @extend .border-top-0;
    font-size: 14px;
    vertical-align: middle;
  }
  tr {
    border-bottom: 1px solid #dee2e6;

    &:first-child td {
      border-top: none;
    }

    &.no-border {
      border-bottom: none;
    }

    td {
      &.helper-text {
        padding-bottom: 0;
      }
      &.sm-width {
        width: 20%;
      }
      &.md-width {
        width: 40%;
      }
      .auction-name {
        max-width: 11em;
      }
      .btn {
        line-height: 1;
      }
    }
  }
}

.bottom-border {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.75rem;
}
