$input-bg: $light-grey;
$input-border-color: $grey;
$input-border-radius: 1px;
$input-disabled-bg: $light-grey;
$input-focus-border-color: $dark-blue;
$custom-control-indicator-bg: white;
$custom-control-indicator-border-color: $dark-blue;
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-checked-border-color: white;
$custom-control-indicator-disabled-bg: white;

form {
  .form-group {
    &.participant-edit-file-field {
      width: 50%;
      margin-left: auto;

      .custom-file-label:after {
        display: none;
      }
    }
  }
}
