#epa_validation_requests,
#participant_registration_requests,
#registered_participants {
  .table {
    &__align-value-columns {
      flex-basis: 19%;
      word-break: break-word;
    }

    &__align-icon-columns {
      flex: 1;
    }
  }
}

#published_draft_postponed_auctions,
#processed_auctions {
  .table {
    &__align-value-columns {
      flex-basis: 12%;
    }
    &__align-button-columns {
      flex-basis: 23%;
    }
    &__align-icon-columns {
      flex: 1;
    }
  }
}

#participant_users {
  .table {
    &__align-value-columns {
      flex-basis: 25%;
    }
  }
}

#manage_users_table,
#invited_users_table {
  .table {
    &__align-value-columns {
      flex-basis: 20%;
    }
    &__align-roles-columns {
      flex-basis: 40%;
    }
  }
}
#invoices {
  .table {
    &__align-value-columns {
      flex-basis: 25%;
    }

    &__align-button-columns {
      flex-basis: 25%;
    }
  }
}

#nzx_users {
  .table {
    &__regular-width {
      flex-basis: 20%;
    }
    &__wide-width {
      flex-basis: 40%;
    }
    &__small-width {
      flex-basis: 10%;
    }
  }
}

#nzx_operators {
  .table {
    &__regular-width {
      flex-basis: 20%;
    }

    &__wide-width {
      flex-basis: 40%;
    }
  }
}

#collateral_participants {
  .table {
    &__align-value-columns {
      flex-basis: 20%;
    }
  }
}
