.styleguide-container {
  & .colour {
    &::before {
      content: "";
      display: block;
      height: 100px;
      width: 100%;
    }

    &--blue-black {
      &::before {
        background-color: $blue-black;
      }
    }

    &--darkest-blue {
      &::before {
        background-color: $darkest-blue;
      }
    }

    &--dark-blue {
      &::before {
        background-color: $dark-blue;
      }
    }

    &--blue {
      &::before {
        background-color: $blue;
      }
    }

    &--green {
      &::before {
        background-color: $green;
      }
    }

    &--red {
      &::before {
        background-color: $red;
      }
    }

    &--dark-grey {
      &::before {
        background-color: $dark-grey;
      }
    }

    &--grey {
      &::before {
        background-color: $grey;
      }
    }

    &--background-grey {
      &::before {
        background-color: $background-grey;
      }
    }

    &--light-grey {
      &::before {
        background-color: $light-grey;
      }
    }
  }
}
