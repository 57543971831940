.breadcrumb {
  background-color: #e8e8e8;
  width: 100%;

  .breadcrumb-item {
    color: $black;

    &.active::before {
      color: $black;
    }
  }

  a {
    color: $black;
  }
}

.form-control-plaintext {
  padding-top: 0;
}

label {
  margin-bottom: 0;
}

.invitation-input {
  width: 580px;
}

.invitation-header {
  margin-left: 16.5rem;
}

.custom-control-label {
  &:hover {
    &::before {
      border: 2px solid $dark-blue;
    }
  }
}

.custom-control-input {
  &:checked {
    & ~ .custom-control-label {
      &::before {
        box-shadow: 0 0 0 1px #1b556b;
      }
    }
  }

  &[disabled="disabled"] {
    & ~ .custom-control-label {
      &::before {
        opacity: 50%;
      }
    }
  }
}

.form-control {
  &:disabled,
  &[readonly] {
    opacity: 0.5 !important;
  }

  &:focus {
    border-width: 2px;
  }
}

.custom-select {
  background-color: $dark-blue;
  background-image: url(../images/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: 15px 9px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: $white;
  border-radius: 10px;
  border: none;

  &:focus {
    background-color: $dark-blue;
    color: $white;
  }
}

.custom-file-label::after {
  background-color: $dark-blue;
  color: $white;
  text-align: center;
  width: 30%;

  .custom-file-input:lang(en) ~ & {
    content: "Choose file";
  }
}

// show time without AM or PM
.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.underline {
  &__card-section {
    border-bottom: 0.5px solid $grey;
  }

  &__page-section {
    border-bottom: 0.5px solid $blue-black;
  }
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ops-label {
  font-size: 14px;
}

.helper-text-on-grey {
  color: $darkest-grey !important;
}

.auction-noticeboard-header {
  background: url(../images/noticeboard-img.png) no-repeat;
  background-position: center;
  background-size: cover;

  &--spacer-row {
    padding-top: 110px;
  }

  &__text {
    background: rgba(255, 255, 255, 0.8);
    background-size: 100%;
  }
}

.auction-noticeboard-post-row:first-of-type {
  border-top: 1px solid #dee2e6 !important;
}

.contact-us-header {
  background: url(../images/contactUs-img.jpg) no-repeat;
  background-position: center;
  background-size: cover;

  &--spacer-row {
    padding-top: 110px;
  }

  &__text {
    background: rgba(255, 255, 255, 0.8);
    background-size: 100%;
  }
}

.training-header {
  background: url(../images/training-img.png) no-repeat;
  background-position: center;
  background-size: cover;

  &--spacer-row {
    padding-top: 110px;
  }

  &__text {
    background: rgba(255, 255, 255, 0.8);
    background-size: 100%;
  }
}

.content-img {
  max-width: 800px;
  width: 100%;
}
