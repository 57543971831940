.navigation {
  &__link {
    @extend .text-white;
    @extend .d-inline-block;
    border-bottom: solid 2px transparent;
    text-decoration: none;

    &:not(.navigation__link--user) {
      &:hover,
      &.current {
        @extend .text-decoration-none;
      }
    }

    &:hover,
    &.current {
      font-weight: $headings-font-weight;
      border-color: $white;
      font-size: 0.975rem;
    }
    &--main {
      @extend .mr-4;
    }

    &--user {
      @extend .border-0;
      @extend .mr-3;
      padding-top: 1.25rem;
      font-size: 0.75rem;
      &:hover {
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
      }
    }
  }
}
