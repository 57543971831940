.breadcrumb {
  background-color: $blue;

  &-item,
  &-item:before,
  & a {
    @extend .text-white;
    text-decoration: none;
  }

  &-item {
    padding: 0.75rem 0;
  }
}
