// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
@import "trix/dist/trix";

.editable-post-wrapper {
  .form-control {
    background: $white;
  }

  // We need to override trix.css’s image gallery styles to accommodate the
  // <action-text-attachment> element we wrap around attachments. Otherwise,
  // images in galleries will be squished by the max-width: 33%; rule.
  .trix-content {
    &.form-control {
      height: auto !important;
      background: $white;
    }

    .attachment-gallery {
      // stylelint-disable-next-line selector-type-no-unknown
      > action-text-attachment,
      > .attachment {
        flex: 1 0 33%;
        padding: 0 0.5em;
        max-width: 33%;
      }

      &.attachment-gallery--2,
      &.attachment-gallery--4 {
        // stylelint-disable-next-line selector-type-no-unknown
        > action-text-attachment,
        > .attachment {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }

    // stylelint-disable-next-line selector-type-no-unknown
    action-text-attachment {
      .attachment {
        padding: 0 !important;
        max-width: 100% !important;
      }
    }
  }
}
