.card {
  @extend .rounded-0;
  @extend .border-0;

  .card-header {
    @extend .rounded-0;
    border-color: $blue-black;
  }

  .card-header-image {
    max-height: 140px;
  }

  .card-title {
    @extend .mb-0;
  }

  a {
    &.underline {
      text-decoration: underline;
    }
  }

  .card-subheader {
    border-bottom: 1px solid $blue-black;
  }

  .card-notice {
    background-color: $light-blue;
    font-size: 0.75rem;
  }

  .justify-content-center {
    img {
      align-self: center;
    }
  }
}
