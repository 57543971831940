$blue-black: #1c2a43;
$darkest-blue: #1b556b;
$darkest-blue-hover: #497789;
$dark-blue: #2f667b;
$blue: #29778e;
$light-blue: #ebf3f6;
$green: #1d781d;
$green-hover: #1c5f1c;
$red: #c84646;
$darkest-grey: #656565;
$dark-grey: #9d9d9d;
$grey: #c4c4c4;
$background-grey: #f1f1f1;
$light-grey: #f6f6f6;
