$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-weight-normal: 400 !default;
$h1-font-size: 1.5rem; //24px
$h2-font-size: 1.125rem; //18px
$h3-font-size: 1rem; //16px
$small-font-size: 0.75rem; //12px
$headings-font-weight: 600;

small {
  color: $dark-grey;
}

h1 {
  font-weight: $font-weight-normal !important;
}
