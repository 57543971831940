.right-arrow {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
  min-height: 10px;
  min-width: 10px;

  &::after {
    background: url(../images/icons/arrow-icon-new.png) no-repeat;
    background-position: center;
    background-size: contain;
    content: "";
    height: 1rem;
    padding: 10px;
    width: 1rem;
  }

  &:hover {
    &::after {
      opacity: 0.7;
    }
  }
}

.nav {
  color: $blue-black;
  text-decoration: none;
  &.current {
    color: $darkest-blue-hover;
    font-weight: bold;
  }
}

.external-link {
  display: inline-block;
  &::after {
    background: url(../images/icons/external-link-icon.svg) no-repeat;
    content: "";
    height: 12px;
    width: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 4px;
  }
}
