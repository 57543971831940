.btn {
  @extend .rounded-pill;
  padding: 0.375rem 2rem;
  line-height: 1rem;
  min-width: 122px;
  font-size: 14px;
}

.btn-primary {
  &:hover {
    background-color: $darkest-blue-hover;
    border-color: $darkest-blue-hover;
  }
  &.disabled {
    opacity: 1 !important;
    background-color: $darkest-grey;
    border-color: $darkest-grey;
  }
}

.btn-outline-primary {
  background-color: $white;
  &:hover {
    background-color: $darkest-blue-hover;
    border-color: $darkest-blue;
  }
}

.btn-success {
  &:hover {
    background-color: $green-hover;
    border-color: $green-hover;
  }
}

.public-page {
  .btn {
    @extend .rounded-pill;
    padding: 0.6875rem 2rem;
    line-height: 1rem;
    min-width: 200px;
    font-size: 14px;
  }
}
