// pagination
.page-link {
  background-color: $background-grey;
  border: none;
  color: $blue-black;
  border-radius: 0.2rem;

  &--bg-white {
    background-color: $white;
  }
}
